<template>
  <div class="container-fluid mt-5">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="row">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CATEGORIES)"
          :to="{ name: 'List Categories' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fa fa-2x fa-layer-group"></i>
            </span>
            <small>{{ $t("COMMON.CATEGORIES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PRODUCTS)"
          :to="{ name: 'List Products' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fa fa-2x fa-cubes"></i>
            </span>
            <small>{{ $t("COMMON.PRODUCTS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_STOCK_MOVEMENTS)"
          :to="{ name: 'List StockMovements' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fa fa-2x fa-truck"></i>
            </span>
            <small>{{ $t("COMMON.STOCK_MOVEMENTS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_WAREHOUSES)"
          :to="{ name: 'List Warehouses' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fa fa-2x fa-building"></i>
            </span>
            <small>{{ $t("COMMON.WAREHOUSES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUPPLY_RULES)"
          :to="{ name: 'List Supply Rules' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fas fa-2x fa-truck-moving"></i>
            </span>
            <small>{{ $t("COMMON.SUPPLY_RULES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PRODUCTS)"
          :to="{ name: 'List UnitOfMeasures' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fa fa-2x fa-cubes"></i>
            </span>
            <small>{{ $t("COMMON.UNIT_OF_MEASURES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CATEGORY_EQUIPMENTS)"
          :to="{ name: 'List CategoryEquipments' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fa fa-2x fa-tag"></i>
            </span>
            <small>{{ $t("COMMON.CATEGORY_EQUIPMENTS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENTS)"
          :to="{ name: 'List Equipments' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="fa fa-2x fa-wrench"></i>
            </span>
            <small>{{ $t("COMMON.EQUIPMENTS") }}</small>
          </a>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
